import React from "react";
import { Card, Chip, Image, Text } from "@legion-ui/core";
import { navigate } from "gatsby";
import { card } from "./Overview.module.scss";

const Overview = ({ data }) => {
  const handleClick = (link) => {
    navigate(link);
  };
  return (
    <React.Fragment>
      {data.map((value, index) => (
        <div
          key={index}
          style={{ width: "100%" }}
          onClick={() => handleClick(value.link)}
        >
          <Card
            className={card}
            bordered
            hoverable
            footer={
              <div>
                {value.website && (
                  <Chip size="sm" color="secondary" variant="soft">
                    Website
                  </Chip>
                )}{" "}
                {value.android && (
                  <Chip size="sm" color="success" variant="soft">
                    Android
                  </Chip>
                )} {value.ios && (
                  <Chip size="sm" color="warning" variant="soft">
                    iOS
                  </Chip>
                )}
              </div>
            }
            media={
              <div style={{ padding: "1rem 1rem 0 1rem" }}>
                <Image alt={value.title} width="100%" src={value.image} />
              </div>
            }
          >
            <div>
              <Text
                as="h3"
                size="18px"
                color="tertiary800"
                mb="16px"
                style={{ fontFamily: "unset" }}
              >
                {value.title}
              </Text>
              <Text size="16px" color="tertiary500">
                {value.description}
              </Text>
            </div>

            {
              /* <Flex alignX="flex-start" direction="row" width="100%">
              {value?.website && (
                <Chip size="sm" variant="soft" color="secondary">
                  Website
                </Chip>
              )}{" "}
              {value?.android && (
                <Chip size="sm" variant="soft" color="success">
                  Android
                </Chip>
              )}{" "}
              {value?.ios && (
                <Chip size="sm" variant="soft" color="warning">
                  iOS
                </Chip>
              )}
            </Flex> */
            }
          </Card>
        </div>
      ))}
    </React.Fragment>
  );
};

export default Overview;
